import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Stack } from '@mui/material';
import Api from "../../../network/Api";
import { Lesson } from "../Types";
import { ManageType, SelectionProps } from "./Selection";
import List from "./List";
import { LoadingButton } from "@mui/lab";

const Recent = (props: SelectionProps) => {

  const [recent, setRecent] = useState<Array<Lesson>>();

  const { hash } = useLocation();

  useEffect(() => {
    switch(hash as ManageType) {
      case ManageType.recentMe:
        Api.get(`/lessons/recent?me`).then(setRecent);
        break;
      case ManageType.recentAll:
        Api.get(`/lessons/recent`).then(setRecent);
        break;
      default:
        break
    }
  }, [hash]);

  return <>
    <h3 className={recent && recent.length > 0 ? "fadedOut" : "animate__animated animate__fadeIn"}>Choose which recent items you want to retrieve.</h3>

    <Stack className={recent && recent.length > 0 ? "fadedOut" : "animate__animated animate__fadeIn animate__delay-2s"} gap={2} direction={{xs: "column", md: "row"}} sx={{pb: 2}}>
      <LoadingButton variant="outlined" 
              className={hash as ManageType == ManageType.recentMe ? "Mui-focused" : ""}
              color="secondary"
              component={NavLink} 
              to={ManageType.recentMe}>Added by me</LoadingButton>

      <LoadingButton variant="outlined" 
              className={hash as ManageType == ManageType.recentAll ? "Mui-focused" : ""}
              color="secondary"
              component={NavLink} 
              to={ManageType.recentAll}>All recent items</LoadingButton>
    </Stack>

    { recent && (recent.length > 0 ? <List lessons={recent} onSelect={props.onSelect} /> : <p>No results</p>) }
  </>;
}

export default Recent;