import React from "react";
import { IconButton, ListItem, ListItemIcon } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Lesson } from "../Types";
import Labeling from "./Labeling";

type LessonsListProps = {
  lessons: Array<Lesson>,
  editable: boolean,
  onChange: (index: number, lesson: Lesson) => void,
  onRemove: (index: number) => void
}

const LessonsList = (props: LessonsListProps) => {
  return <>
    <h3>Enter your lessons learned, either one by one, or use the importer to bulk-add items. Hit the save button once you're finished adding.</h3>
    
    { props.lessons.length > 0 && <>
      { props.lessons.map((lesson, index) => {
        return <ListItem
                  className="animate__animated animate__fadeIn"
                  key={lesson.id}
                  disablePadding
                >
          <ListItemIcon>
            { props.editable && 
              <IconButton onClick={() => props.onRemove(index)}>
                <DeleteOutlineIcon color="secondary" />
              </IconButton>
            }
          </ListItemIcon>
          
          <div>
            <p><strong>{lesson.learned}</strong></p>
            <Labeling lesson={lesson} onChange={lesson => props.onChange(index, lesson)} />
          </div>
        </ListItem>
      })}
    </> }
 </>;
}

export default LessonsList;