import React, { useState } from 'react';
import { FormGroup, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { OnboardingProps } from './WorkspaceOnboarding';
import FormUtils from '../../utils/FormUtils';
import OnboardingLayout from "./Layout";

const UserOnboarding = (props: OnboardingProps) => {

  const [isLoading, shouldLoad] = useState<boolean>(false);

  return <OnboardingLayout title="Your workspace is waiting">
    <form action="/user" method="put" onSubmit={e => { FormUtils.onFormSubmit(e); shouldLoad(true); } }>
      <FormGroup><p>Please enter your display name that will be visible to your workspace:</p></FormGroup>
      <FormGroup><TextField name="display_name" label="Your display name" defaultValue={props.user.display_name} required variant="outlined" /></FormGroup>
      <FormGroup><p>Tell us more more about your role in the company. This will help getting better results. You can skip this step if you want and come back later.</p></FormGroup>
      <FormGroup><TextField name="position_name" label="Current position" defaultValue={props.user.display_name} variant="outlined" /></FormGroup>
      <FormGroup><TextField name="position_description" label="Main responsibility" defaultValue={props.user.display_name} variant="outlined" /></FormGroup>
      <LoadingButton loading={isLoading} variant="outlined" type="submit" sx={{float: 'right'}}>Save</LoadingButton>
    </form>
  </OnboardingLayout>
};

export default UserOnboarding;