import React, { useEffect, useState } from 'react';
import { Divider, Button, FormGroup, FormLabel, IconButton, FormControlLabel, Radio, Chip, RadioGroup } from '@mui/material';
import Toolbox, { ToolboxProps } from "../Shared/Toolbox";
import { StartProps } from '../Lessons/Start';
import Api from '../../network/Api';
import User, { Role } from '../../types/User';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { LoadingButton } from '@mui/lab';

const Members = (props: StartProps & ToolboxProps) => {

  const [users, setUsers] = useState<Array<User>>();
  const [editUser, shouldEdit] = useState<User>();

  useEffect(() => {
    if (typeof(editUser) !== "undefined") { return }
    Api.get("/workspace/users").then(setUsers)
  }, [editUser])

  return <>    
    <FormGroup>
      <p><strong>Manage Members</strong></p>
    </FormGroup>

    { users?.map(user => { return <>
      <FormLabel>{user.display_name} <IconButton onClick={() => shouldEdit(user)}><MoreHorizIcon /></IconButton></FormLabel>
      <FormLabel>{atob(user.email)}</FormLabel>
      <FormLabel><Chip size="small" label={<strong>{Role[user.role]}</strong>} /></FormLabel>
    </> }) }

    { editUser && <EditUserRole workspace={props.workspace} user={editUser} onClose={() => shouldEdit(undefined)} /> }
  </>
}

const EditUserRole = (props: StartProps & ToolboxProps) => {

  const [role, asRole] = useState<Role>();
  const [isUpdating, shouldUpdate] = useState(false);
  
  useEffect(() => {
    if (!isUpdating) { return }
    const roleKey = Object.keys(Role).find(key => Role[key] == role);
    Api.put(`/workspace/users/${props.user.id}`, {role: roleKey}).then(result => {
      shouldUpdate(false);
      props.onClose();
    });
  }, [isUpdating]);

  return <Toolbox maxWidth='sm' title="Change user role" onClose={props.onClose}><>
    <p>Select the account type <strong>{props.user.display_name}</strong> should have for <strong>{props.workspace.name}</strong>.</p>
    
    <FormLabel>Choose account type</FormLabel>
    <RadioGroup
      defaultValue={props.user.role}
      name="role"
      onChange={(e, value) => asRole(Role[value])}>
      { Object.keys(Role).map((key, index) => {
        return <FormControlLabel value={key} control={<Radio />} label={Object.values(Role)[index]} />
      })}
    </RadioGroup>

    <FormGroup row>
      <Button variant="outlined" color="secondary" onClick={props.onClose}>Cancel</Button>
      <LoadingButton variant="outlined" color="primary" onClick={() => shouldUpdate(true)}>Save</LoadingButton>
  </FormGroup>
  </></Toolbox>
}

export default Members;