import React, { useState, useEffect, useRef } from "react";
import { Card, CircularProgress, Container, Modal, Menu, ListItemText, ListItemIcon, MenuItem, Button, Stack, Table, TableHead, TableRow, TableCell, Switch, FormControlLabel, Checkbox, TableBody } from '@mui/material';
import Dropzone, { Accept } from 'react-dropzone';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import FormatTextdirectionLToRIcon from '@mui/icons-material/FormatTextdirectionLToR';
import EditNoteIcon from '@mui/icons-material/EditNote';
import Api from "../../../network/Api";
import { LoadingButton } from "@mui/lab";
import Toolbox from "../../Shared/Toolbox";

type ImporterProps = {
  accept: Accept,
  onCancel: () => void,
  onEdit: (lessons: Array<string>) => void,
  onUploaded: () => void
}

type ImportButtonProps = {
  onImport: (accept: Accept) => void 
}

type Analysis = {
  rows: Array<Array<string>>
}

export const ImportButtons = (props: ImportButtonProps) => {

  const [showsOptions, showOptions] = useState(false);
  const [anchorEl, setAnchorEl] = useState();

  const onImport = (accept: Accept) => {
    props.onImport(accept);
    showOptions(false);
  }

  return <>
    <Button size="small" 
            color="secondary" 
            sx={{mr: '1rem'}} 
            onClick={e => {
              setAnchorEl(e.currentTarget);
              showOptions(true);
            }}>
      <UploadFileIcon color="primary" />
    </Button>

    <Menu
      anchorEl={anchorEl}
      open={showsOptions}
      onClose={() => showOptions(false)}
    >
      <MenuItem onClick={() => onImport({ 'text/plain': ['.txt'] })}>
        <ListItemIcon>
          <FormatTextdirectionLToRIcon />
        </ListItemIcon>
        <ListItemText>
          Import Text file
        </ListItemText>
      </MenuItem>

      <MenuItem onClick={() => onImport({ 'text/csv': ['.csv'] })}>
        <ListItemIcon>
          <TextSnippetIcon />
        </ListItemIcon>
        <ListItemText>
          Import CSV file
        </ListItemText>
      </MenuItem>
    </Menu>
  </>
}

const Importer = (props: ImporterProps) => {

  const [file, setFile] = useState<File>();
  const [isAnalyzing, shouldAnalyze] = useState(false);
  const [isUploading, shouldUpload] = useState(false);
  const [analysis, setAnalysis] = useState<Analysis>();
  const [selectedCols, useCols] = useState<Array<number>>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [editableItems, setEditableItems] = useState<Array<string>>([]);
  const [includeFirstRow, shouldIncludeFirstRow] = useState(false);

  const prepare = (file: File) => {
    setFile(file);
    shouldAnalyze(true);
  }

  useEffect(() => {
    if (typeof(file) === "undefined") { return }
    shouldAnalyze(true);
    Api.upload("/lessons/import", file, {}).then((result: Analysis) => {
      shouldAnalyze(false);
      setAnalysis(result);
      if (result.rows.length > 0 && result.rows[0].length === 1) {
        useCols([0]);
        shouldIncludeFirstRow(true);
      }
    })
  }, [file]);

  useEffect(() => {
    if (!analysis || analysis.rows.length <= 0) { return }
    const total = selectedCols.length * (analysis.rows.length - (includeFirstRow ? 0 : 1));
    setTotalCount(total);
  }, [selectedCols, includeFirstRow]);

  useEffect(() => {
    if (totalCount <= 0 || totalCount > 20 || !analysis) {
      setEditableItems([]);
      return
    }
    setEditableItems(analysis.rows.flatMap((row, rowIndex) => rowIndex >= (includeFirstRow ? 0 : 1) ? row.filter((col, colIndex) => selectedCols.includes(colIndex)) : []));
  }, [totalCount]);

  useEffect(() => {
    if (!isUploading || typeof(file) === "undefined") { return }
    Api.upload("/lessons", file, { cols: selectedCols, first_row: includeFirstRow }).then(result => {
      if (result?.error) {
        shouldUpload(false);
        return
      }
      props.onUploaded();
    })
  }, [isUploading]);

  const toggleColumn = (col: number, checked: boolean) => {
    if (checked) {
      useCols(current => [...current, col]);
    } else {
      useCols(current => current.filter(item => item != col));
    }
  }

  return <Toolbox title={`Import lessons learned from ${Object.entries(props.accept).map(([key, values]) => {
      return values;
    })} file`} maxWidth={analysis ? "md" : "sm"} onClose={props.onCancel}><>
    { analysis && analysis.rows.length > 0 ? <>
      <Stack direction="row" alignItems="center">
        <p>Preview <strong>{file?.path}</strong>{ analysis.rows[0].length > 1 && <><br />Select the columns to import:</>}</p>
        <FormControlLabel control={<Switch checked={includeFirstRow} onChange={(e, checked) => shouldIncludeFirstRow(checked)} />} label="Include first row" sx={{ml: 'auto'}} />
      </Stack>

      <Table sx={{width: '100%', overflowX: 'auto', display: 'block'}}>
        { analysis.rows[0].length > 1 && <TableHead>
          <TableRow>
            { analysis.rows[0].map((col, index) => {
              return <TableCell sx={{textAlign: 'center'}}><Checkbox onChange={(e, checked) => toggleColumn(index, checked)} /></TableCell>
            }) }
          </TableRow>
        </TableHead> }
        <TableBody>
        { analysis.rows.slice(0, 2).map((row, index) => {
          return <TableRow className={includeFirstRow || index > 0 ? "" : "fadedOut"}>
            { row.map(col => <TableCell>{col}</TableCell> ) }
          </TableRow> 
        }) }
        </TableBody>
      </Table>
      { analysis.rows.length > 2 && <p className="text-center"><small>+ {analysis.rows.length - 2} additional items</small></p> }
    </> : <Dropzone 
      accept={props.accept}
      multiple={false} 
      maxFiles={1}
      disabled={isAnalyzing}
      onDrop={acceptedFiles => prepare(acceptedFiles[0])}>
      {({getRootProps, getInputProps}) => (
        <div className='file-dropzone' {...getRootProps()}>
          <input {...getInputProps()} />
          { isAnalyzing ? <>
            <CircularProgress color="secondary" sx={{mb: '0.5rem'}} />
            <p>Analyzing <strong>{file?.path}</strong> ...</p>
          </> : <>
            <p>Click to select, or drag'n'drop <strong>{Object.entries(props.accept).map(([key, values]) => {
              return values;
            })}</strong> file.</p>
            <UploadFileIcon fontSize="large" className="text-secondary" />
          </> }
        </div>
      )}
    </Dropzone> }

    <Stack direction="row" spacing={2} justifyContent='center' alignItems='center'>
      <Button size="small" 
              variant="outlined" 
              color="secondary"
              onClick={props.onCancel}>Cancel</Button>

      { totalCount > 0 && <>
        { editableItems.length > 0 && <Button size="small" 
                variant="outlined" 
                color="secondary"
                startIcon={<EditNoteIcon />}
                onClick={() => props.onEdit(editableItems)}>Review {totalCount} items</Button> }

        <LoadingButton 
                loading={isUploading}
                variant="outlined"
                color="primary"
                startIcon={<CloudUploadIcon />}
                onClick={() => shouldUpload(true)}>Save {totalCount} items</LoadingButton> 
      </> }
    </Stack>
  </></Toolbox>
}

export default Importer;