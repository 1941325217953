import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Button, Container, Stack, Box } from '@mui/material';
import NewLesson from "./Add/New";

import Toolbox from "../Shared/Toolbox";
import Settings from "../User/Settings";
import WorkspaceSettings from "../Workspaces/Settings";
import Workspace from "../../types/Workspace";
import Manage from "./Manage/Selection";

import PostAddIcon from '@mui/icons-material/PostAdd';
import HistoryIcon from '@mui/icons-material/History';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

import { Lesson } from "./Types";
import User from "../../types/User";
import Invite from "../Invitations/Invite";

export type StartProps = {
  user: User,
  workspace: Workspace
}
type SelectionProps = {
  title: string,
  showsDraft: boolean
}

const Start = (props: StartProps) => {

  const [draftLessons, storeLessons] = useState<Array<Lesson>>(JSON.parse(localStorage.getItem('new_lessons')) ?? []);

  const { pathname, hash } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem('new_lessons', JSON.stringify(draftLessons));
  }, [draftLessons]);

  const renderSelectedContent = () => {
    switch(pathname) {
      case "/lessons/new":
        return <NewLesson
                  defaultValue={hash === "#continue" ? draftLessons : undefined}
                  onChange={storeLessons} />
      case "/lessons":
        return <Manage />
      default:
        return <Selection 
                  title={hash === "#completed" ? "Congratulations - you have completed the task!<br />What do you want to do next?" : "What do you want to do today?"}
                  showsDraft={draftLessons && draftLessons.length > 0} />
    }
  }

  return <>
    { renderSelectedContent() }

    <Container maxWidth="md">
      <footer className="text-center" style={{margin: "1rem 0"}}>
        <p>
          <small>{props.workspace.name} data aren't used in other workspaces.</small>
        </p>
      </footer>
    </Container>

    { pathname === '/user' && <Toolbox title="Account Settings" onClose={() => navigate('/app')}><Settings {...props} onClose={() => navigate('/app')} /></Toolbox> }
    { pathname === '/workspace' && <Toolbox title="Workspace Settings" onClose={() => navigate('/app')}><WorkspaceSettings {...props} onClose={() => navigate('/app')} /></Toolbox> }
    { pathname === '/workspace/invitations' && <Toolbox title={`Invite people to ${props.workspace.name}`} maxWidth="sm" onClose={() => navigate('/app')}><Invite {...props} onClose={() => navigate('/app')} /></Toolbox> }
  </>;
}

const Selection = (props: SelectionProps) => {

  return <Container maxWidth="md" sx={{margin: 'auto'}}><Stack spacing={3}>
    <h2 style={{textAlign: 'center'}} 
      className='animate__animated animate__fadeInUp'
      dangerouslySetInnerHTML={{__html: props.title }}>
    </h2>

    <Stack direction={{xs: "column", sm: "row"}} gap={2} sx={{justifyContent: 'center'}}>

      { props.showsDraft && <Button variant="text"
        className="text-center animate__animated animate__fadeInUp animate__delay-2s"
        color="warning"
        size="medium"
        startIcon={<HistoryIcon />}
        component={NavLink}
        to="/lessons/new#continue">Continue Draft</Button> }

      <Box className="text-center animate__animated animate__fadeInUp animate__delay-2s">
        <Button variant="outlined" 
                color="secondary"
                size="medium"
                startIcon={<PostAddIcon />}
                component={NavLink}
                to="/lessons/new">Add Lessons Learned</Button>
      </Box>

      <Box className="text-center animate__animated animate__fadeInUp animate__delay-2s">
        <Button variant="outlined" 
                color="secondary"
                size="medium"
                startIcon={<AutoAwesomeIcon />}
                component={NavLink}
                to="/lessons">Manage Knowledge</Button>
      </Box>
    </Stack>

  </Stack></Container>
}

export default Start;