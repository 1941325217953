import React, { useState, useEffect } from "react";
import { CircularProgress, TextField, Autocomplete, Chip, Stack } from '@mui/material';
import Api from "../../../network/Api";
import { Lesson, SentimentType } from "../Types";

import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';

type LabelingProps = {
  lesson: Lesson,
  onChange: (lesson: Lesson) => void
}

type TaggingProps = {
  value?: Array<string>,
  onChange: (items: Array<string>) => void
}

const Labeling = (props: LabelingProps) => {

  const [isLoading, shouldLoad] = useState<boolean>(false);
  const [lesson, setLesson] = useState<Lesson>(props.lesson);

  useEffect(() => {
    if (lesson.sentiment) { return }
    shouldLoad(true);
  }, []);

  useEffect(() => {
    if (!isLoading) { return }
    Api.post("/labels", { lesson: lesson }).then(result => {
      if (result?.sentiment) {
        setLesson(current => ({...current, sentiment: result.sentiment, labels: result.labels}));
      }
      shouldLoad(false);
    });
  }, [isLoading]);

  useEffect(() => {
    props.onChange(lesson);
  }, [lesson]);

  const renderSentiment = (sentiment: SentimentType) => {
    switch (sentiment) {
      case SentimentType.Positive:
        return <SentimentSatisfiedAltIcon color="success" fontSize="small" />
      case SentimentType.Negative:
        return <SentimentVeryDissatisfiedIcon color="warning" fontSize="small" />
      default:
        return <SentimentNeutralIcon color="secondary" fontSize="small" />
    }
  }

  return <>{ isLoading ? <CircularProgress size={28} /> : <Stack spacing={1} direction="row" alignItems="center">
    { props.lesson.sentiment && renderSentiment(props.lesson.sentiment) }
    <Tagging value={props.lesson.labels}
              onChange={labels => setLesson(current => ({...current, labels: labels }))} />
  </Stack> }</>
}

const Tagging = (props: TaggingProps) => {

  return <Autocomplete
            multiple
            options={[]}
            value={props.value ?? []}
            freeSolo
            disableClearable
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip color="secondary" size="small" {...getTagProps({ index })} label={<strong>{option}</strong>} />
              ))
            }
            onChange={(e, value) => props.onChange(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                placeholder="Add your topic..."
              />
            )}
            className="animate__animated animate__fadeIn"
          />;
}

export default Labeling;