import React from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Container, Stack, Button } from '@mui/material';

import HistoryIcon from '@mui/icons-material/History';
import SearchIcon from '@mui/icons-material/Search';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import Recent from "./Recent";
import { Lesson } from "../Types";
import Api from "../../../network/Api";
import Search from "./Search";
import Trending from "./Trending";

export enum ManageType {
  recent = "#recent",
  recentMe = "#recent-me",
  recentAll = "#recent-all",
  search = "#search",
  context = "#context",
  trending = "#trending",
  trendingPositive = "#trending-positive",
  trendingNegative = "#trending-negative",
}

export type SelectionProps = {
  onSelect: (lessons: Array<Lesson>) => void
}

const Ask = () => {

  const { hash } = useLocation();
  const navigate = useNavigate();

  const createSheet = (from: Array<Lesson>) => {
    Api.post("/sheets", { lessons: from.map(lesson => lesson.id) }).then(result => {
      if (!result?.id) { return }
      navigate(`/sheets/${result.id}`);
    })
  }

  const renderSelectedContent = () => {
    switch(hash as ManageType) {
      case ManageType.recent:
      case ManageType.recentMe:
      case ManageType.recentAll:
        return <Recent onSelect={createSheet} />
      case ManageType.search:
        return <Search onSelect={createSheet} />
      case ManageType.trending:
      case ManageType.trendingPositive:
      case ManageType.trendingNegative:
        return <Trending onSelect={createSheet} />
      case ManageType.context:
        return <>Delivering topics for the role of a --select-role-- with specific fields in --add-more-details--</>
      default:
        return <></>
    }
  }

  return <Stack className="animate__animated animate__fadeIn" sx={{overflowY: 'auto', alignItems: 'center', marginY: 'auto'}}>
    <Container maxWidth="md">
      <Stack spacing={3} sx={{py: 4}}>

        <h3 className={hash ? "fadedOut" : ""}>Where do you want to start?</h3>

        <Stack className={hash ? "fadedOut" : ""} gap={2} direction={{xs: "column", md: "row"}} sx={{flexWrap: 'wrap', pb: 2}}>
          <Button variant="outlined"
                  className={[ManageType.recent, ManageType.recentAll, ManageType.recentMe].includes(hash as ManageType) ? "Mui-focused" : ""} 
                  color="secondary"
                  startIcon={<HistoryIcon />}
                  component={NavLink} to={ManageType.recent}>Recently added</Button>

          <Button variant="outlined" 
                  className={hash as ManageType == ManageType.search ? "Mui-focused" : ""} 
                  color="secondary"
                  startIcon={<SearchIcon />}
                  component={NavLink} to={ManageType.search}>Search by topic</Button>

          <Button variant="outlined" 
                  className={hash as ManageType == ManageType.context ? "Mui-focused" : ""} 
                  color="secondary"
                  startIcon={<AutoAwesomeIcon />}
                  component={NavLink} to={ManageType.context}>Topics that affect me</Button>

          <Button variant="outlined" 
                  className={hash as ManageType == ManageType.trending ? "Mui-focused" : ""} 
                  color="secondary"
                  startIcon={<TrendingUpIcon />}
                  component={NavLink} to={ManageType.trending}>Trending topics</Button>
        </Stack>

        { renderSelectedContent() }

      </Stack>
    </Container>
  </Stack>
}

export default Ask;