import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {} from '@mui/material';
import Api from "../../network/Api";

type Sheet = {
  name: string
}

const EditSheet = () => {

  const { sheetId } = useParams();
  const [sheet, setSheet] = useState<Sheet>();

  useEffect(() => {
    Api.get(`/sheets/${sheetId}`).then(result => {
      setSheet(result);
    })
  }, []);

  return <>
    <h3>{sheet?.name}</h3>
  </>;
}

export default EditSheet;