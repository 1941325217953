import React, { useState, useEffect } from "react";
import { Autocomplete, Chip, TextField, ToggleButtonGroup, ToggleButton, Stack } from '@mui/material';
import Api from "../../../network/Api";
import { Lesson } from "../Types";
import { SelectionProps } from "./Selection";
import List from "./List";
import { LoadingButton } from "@mui/lab";

import SearchIcon from '@mui/icons-material/Search';

type Label = {
  id: string,
  name: string
}

enum MatchValue {
  all,
  any
}

const Search = (props: SelectionProps) => {

  const [topics, setTopics] = useState<Array<Label>>([]);
  const [match, setMatch] = useState<MatchValue>(MatchValue.any);
  const [selected, setSelected] = useState<Array<Label>>([]);
  const [result, setResult] = useState<Array<Lesson>>();

  useEffect(() => {
    Api.get("/labels").then(setTopics)
  }, []);

  const search = () => {
    if (selected.length <= 0) { return }
    Api.post("/lessons/search", { labels: selected.map(label => label.id), match: match }).then(setResult);
  }

  return <>
    <h3 className="animate__animated animate__fadeIn">Add the topics you want to search for. You can add multiple topics.</h3>

    <Autocomplete
        multiple
        options={topics}
        getOptionLabel={(option: Label) => option.name}
        value={selected}
        disableClearable
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip {...getTagProps({ index })} label={<strong>{option.name}</strong>} />
          ))
        }
        onChange={(e, value) => setSelected(value)}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="filled"
            placeholder="Choose your topics..."
          />
        )}
        className="animate__animated animate__fadeIn"
      />

    <Stack direction="row" spacing={2} alignItems="center" sx={{pb: 2}}>
      <ToggleButtonGroup size="small" onChange={(e, value) => setMatch(value[0])} sx={{display: 'inline'}}>
        <ToggleButton value={MatchValue.any}
                      selected={match == MatchValue.any}>Match any</ToggleButton>

        <ToggleButton value={MatchValue.all}
                      selected={match == MatchValue.all}>Match all</ToggleButton>
      </ToggleButtonGroup>

      <LoadingButton variant="outlined"
                      color="secondary"
                      disabled={selected.length <= 0}
                      startIcon={<SearchIcon />}
                      onClick={search}>Search</LoadingButton>
    </Stack>

    { result && result.length > 0 && <List lessons={result} onSelect={props.onSelect} /> }
  </>;
}

export default Search;