import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Stack, FilledInput, ListItem, Box, Container } from '@mui/material';
import Api from "../../../network/Api";
import SendIcon from '@mui/icons-material/Send';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { LoadingButton } from "@mui/lab";
import LessonsList from "./List";
import { Lesson } from "../Types";
import Importer, { ImportButtons } from "./Importer";
import { Accept } from "react-dropzone";

export type NewLessonProps = {
  defaultValue?: Array<Lesson>,
  onChange: (lessons: Array<Lesson>) => void
}

const NewLesson = (props: NewLessonProps) => {

  const [input, setInput] = useState<string>("");
  const [lessons, setLessons] = useState<Array<Lesson>>(props.defaultValue ?? []);
  const [isSaving, shouldSave] = useState(false);
  const [importing, shouldImport] = useState<Accept>();

  const initialLoad = useRef(true);
  const scrollRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    if (initialLoad.current) {
      initialLoad.current = false;
      return;
    }
    props.onChange(lessons);
  }, [lessons]);

  useEffect(() => {
    if (input === "" || typeof(input) === "undefined") {
      if (scrollRef.current) {
        scrollRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [input]);

  const addLesson = (input: string) => {
    if (typeof(input) === "undefined" || !input.replace(/\s/g, '').length) { return }
    const newLesson: Lesson = { id: `${new Date().getTime()}`, learned: input }
    setLessons(current => [...current, newLesson]);
    setInput("");
  }

  const updateLesson = (index: number, lesson: Lesson) => {
    setLessons(current => {
      return current.map((item, tick) => {
        if (tick != index) { return item }
        return lesson;
      })
    })
  }

  const removeLesson = (index: number) => {
    setLessons(current => {
      return current.filter((item, tick) => tick != index);
    });
  }

  const onLessonsStored = () => {
    navigate("/app#completed");
  }

  useEffect(() => {
    if (!isSaving || lessons.length <= 0) { return }
    Api.post(`/lessons`, { lessons: lessons }).then(result => {
      if (result?.error) {
        shouldSave(false);
        return
      }
      props.onChange([]);
      onLessonsStored();
    })
  }, [isSaving]);

  return <Stack className="animate__animated animate__fadeIn" marginY={"auto"} flexGrow={lessons.length > 0 ? 1 : 0}>
    <Stack sx={{flex: lessons.length > 0 ? '1 1 0' : 1, overflowY: 'auto', py: 4, alignItems: 'center'}}>

      <Container maxWidth="md">
        <Stack spacing={3}>
          <LessonsList lessons={lessons} 
                        editable={!isSaving}
                        onChange={updateLesson}
                        onRemove={removeLesson} />
          
          { lessons.length > 0 && <ListItem>
            <LoadingButton variant="outlined"
                          loading={isSaving}
                          color="primary"
                          startIcon={<CloudUploadIcon />}
                          onClick={() => shouldSave(true)}>Save {lessons.length} items</LoadingButton>
            <Box ref={scrollRef} /> 
          </ListItem> }
        </Stack>
      </Container>
    </Stack>

    <Container maxWidth="md">
      <FilledInput placeholder="Enter your lessons learned one by one" 
                    fullWidth
                    autoComplete="off"
                    name="lesson"
                    startAdornment={<ImportButtons onImport={shouldImport} />}
                    endAdornment={<Button size="small" sx={{ml: '0.5rem'}} onClick={() => addLesson(input)}><SendIcon /></Button>}
                    value={input}
                    onChange={e => setInput(e.target.value)}
                    onKeyDown={e => e.key == "Enter" && addLesson(input)} />
    </Container>

    { importing && <Importer accept={importing} 
                             onCancel={() => shouldImport(undefined)}
                             onEdit={lessons => {
                              shouldImport(undefined);
                              lessons.map(lesson => addLesson(lesson));
                             }}
                             onUploaded={onLessonsStored} /> }
    
  </Stack>;
}

export default NewLesson;