import React, { useState, useEffect } from "react";
import { Checkbox, ListItem, ListItemIcon, ListItemText, Stack, Button } from '@mui/material';
import { Lesson } from "../Types";
import { LoadingButton } from "@mui/lab";

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { SelectionProps } from "./Selection";

type LessonsListProps = {
  lessons: Array<Lesson>
}

const LessonsList = (props: LessonsListProps & SelectionProps) => {

  const [selected, setSelected] = useState<Array<Lesson>>([]);

  useEffect(() => {
    setSelected(props.lessons);
  }, [props.lessons]);

  const toggle = () => {
    if (selected.length > 0) {
      setSelected([]);
    } else {
      setSelected(props.lessons);
    }
  }

  const select = (lesson: Lesson) => {
    setSelected(current => [...current, lesson]);
  }

  const unselect = (lesson: Lesson) => {
    setSelected(current => {
      return current.filter(item => item.id != lesson.id);
    })
  }

  return <>
    <h3>Select the items you want to manage. Hit the manage button when you're ready.</h3>
    
    { props.lessons.map(lesson => {
      return <ListItem
                className="animate__animated animate__fadeIn"
                key={lesson.id}
                disablePadding
              >
        <ListItemIcon>
          <Checkbox checked={selected.includes(lesson)}
                    id={`label_${lesson.id}`}
                    onChange={e => e.target.checked ? select(lesson) : unselect(lesson)} />
        </ListItemIcon>
        
        <ListItemText><label for={`label_${lesson.id}`}>{lesson.learned}</label></ListItemText>
      </ListItem>
    })}

    <Stack direction="row" spacing={2} alignItems="center">
      <Button variant="outlined"
              size="small" 
              color="secondary"
              startIcon={selected.length > 0 ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
              onClick={toggle}>Select / Unselect all</Button>

      <LoadingButton variant="outlined"
                      color="primary"
                      disabled={selected.length <= 0}
                      endIcon={<NavigateNextIcon />}
                      onClick={() => props.onSelect(selected)}>Manage {selected.length} items</LoadingButton>
    </Stack>
 </>;
}

export default LessonsList;