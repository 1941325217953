export enum SentimentType {
  positive,
  negative,
  neutral
}

export type Lesson = {
  id: string,
  learned: string,
  sentiment?: SentimentType,
  labels?: Array<string> 
}